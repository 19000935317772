import React, { useEffect, useState } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { observer } from 'mobx-react';

import AccountsAndPlans from '@pages/AccountsAndPlans';
import CreateAccount from '@pages/Auth/CreateAccount';
import CreatePassword from '@pages/Auth/CreatePassword';
import Dashboard from '@pages/Dashboard';
import Login from '@pages/Auth/Login';
import NewCalendar from '@pages/NewCalendar/NewCalendar';
import NewContactsPage from '@pages/NewContacts/NewContactsPage';
import NewToDos from '@pages/NewToDos';
import NewUserSettingsPage from '@pages/NewUserSettings';
import PrivateRoute from '@components/PrivateRoute';
import ResetPassword from '@pages/Auth/ResetPassword';
import RestorePassword from '@pages/Auth/RestorePassword';
import SalesCycle from '@pages/SalesCycle';
import TestPage from '@pages/TestPage';
import Trash from '@pages/Trash';
import VerifiedEmailByHash from '@pages/VerifiedEmailByHash';

import { ROUTES } from '@constants/routes';

import { useStore } from '@store';

const isTestRender = process.env.NODE_ENV === 'development';

export const AppSwitch = observer(() => {
  const authStore = useStore().AuthStore;
  
  const [ showContent, setShowContent ] = useState(false);

  useEffect(() => {
    setShowContent(true);
  }, [authStore.token]);

  return showContent ? (
    <Switch>
      <PrivateRoute exact path={ ROUTES.home }>
        <Redirect
          to={ {
            pathname: ROUTES.dashboard,
          } }
        />
      </PrivateRoute>
      <PrivateRoute path={ ROUTES.calendar }>
        <NewCalendar />
      </PrivateRoute>
      <PrivateRoute path={ ROUTES.dashboard }>
        <Dashboard />
      </PrivateRoute>
      <PrivateRoute path={ ROUTES.contacts }>
        <NewContactsPage />
      </PrivateRoute>
      <PrivateRoute path={ ROUTES.userSettings }>
        <NewUserSettingsPage />
      </PrivateRoute>
      <PrivateRoute path={ ROUTES.toDos }>
        <NewToDos/>
      </PrivateRoute>
      <Route path={ ROUTES.login }>
        <Login />
      </Route>
      {/* <Route path={ ROUTES.register }>
        <CreateAccount />
      </Route> */}
      <Route path={ ROUTES.createPassword }>
        <CreatePassword />
      </Route>
      <Route path={ ROUTES.resetPassword }>
        <ResetPassword />
      </Route>
      <Route path={ ROUTES.restorePasswordToken }>
        <RestorePassword />
      </Route>
      <Route path={ ROUTES.verifiedEmailByHash }>
        <VerifiedEmailByHash />
      </Route>
      <PrivateRoute path={ ROUTES.salesCyclePath }>
        <SalesCycle />
      </PrivateRoute>
      <PrivateRoute path={ ROUTES.accountsAndPlans }>
        <AccountsAndPlans />
      </PrivateRoute>
      <PrivateRoute path={ ROUTES.trash }>
        <Trash />
      </PrivateRoute>

      {isTestRender && (
        <PrivateRoute path={ ROUTES.test }>
          <TestPage />
        </PrivateRoute>
      )}

      <Route component={ () => <h1>Not found!</h1> } />
    </Switch>
  ) : null;
});
