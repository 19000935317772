
import React, { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';

import { AppPage } from '@modules/AppPage';
import Surface from '@components/BasicLayoutComponents/Surface';

import { observer } from 'mobx-react';
import { useStore } from '@store';
import { NOTIFICATION_TYPES } from '@constants/notifications';
import CustomSelect from '@components/CustomSelect';
import TagButton, { TAG_BUTTON_TYPES, TAG_BUTTON_VARIANTS } from '@components/TagButton';

export const TestPage = observer(() => {
  const { showNotification, triggerCloseAll } = useStore().NotificationsStore;

  const [test, setTest] = useState(
    {
      label: 'Counted',
      count: 4,
      id: 5
    }
  );

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  // useEffect(() => {
  //   enqueueSnackbar('test', { variant });
  // }, []);

  return (
    <AppPage>
      <AppPage.AppHeader>
        <AppPage.AppPageTitle title={ 'Test' }/>
      </AppPage.AppHeader>
      <Surface.Content $isWrap={ false }>
        <Surface.Main>
          <div style={ {
            display: 'flex',
            height: '200px',
            width: '300px',
            flexDirection: 'column',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
            outline: '1px solid red',
            margin: '20px'
          } }>
            <TagButton
              content={ 'BUTTON' }
              onClickCb={ () => {
              } }
              type={ TAG_BUTTON_TYPES.button }
            />
            <TagButton
              $variant={ TAG_BUTTON_VARIANTS.system }
              content={ 'VIEW' }
              type={ TAG_BUTTON_TYPES.view }
            />

            <TagButton
              onDeleteCb={ () => {} }
              onSaveCb={ () => {} }
              tagData={ {
                value: 'without Count',
                id: '1'
              } }
              type={ TAG_BUTTON_TYPES.edit }
            />

            <TagButton
              onDeleteCb={ (data) => { console.log('onDelete', data); } }
              onEditEnd={ () => { console.log('onEditEND'); } }
              onEditStart={ () => { console.log('onEditSTART'); } }
              onSaveCb={ (data) => {
                setTest(data as any);
                console.log('onSave', data);
              } }
              tagData={ test }
              type={ TAG_BUTTON_TYPES.edit }
            />
          </div>
        </Surface.Main>
      </Surface.Content>
    </AppPage>
  );
});
