import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { useForm, useWatch } from 'react-hook-form';

import { useStore } from '@store';

import { CONTACT_NOTES_AND_HISTORY_INPUT_NAMES } from '@constants/contactNote';

import { BusinessDetails, ContactInformation } from './components';
import NotesAndHistory, { NOTE_AND_HISTORY_TYPE } from '@modules/NotesAndHistory';
import Surface from '@components/BasicLayoutComponents/Surface';

import { NotesAndHistoryWrapper, OverviewWrapper, SURFACE_CONTENT_WRAPPER_STYLE_PROPS } from './styles';

import { ContactDetailsOverviewProps } from './types';
import { ContactOverviewFormFields } from '@/shared/types/contactOverview';


export const ContactDetailsOverview = observer(({
  contactId,
}: ContactDetailsOverviewProps) => {
  const contactDetailsOverview = useStore().ContactDetailsOverview;
  const {
    contact,
    init,
    notesUtilsStore,
    predefinedTags,
    resetStore,
    saveNote
  } = contactDetailsOverview;

  const methods = useForm<ContactOverviewFormFields>();
  const { profile } = useStore().SettingsStore;

  const contactNotes = useWatch({
    control: methods.control,
    name: CONTACT_NOTES_AND_HISTORY_INPUT_NAMES.notes
  });

  useEffect(() => {
    init(contactId, methods);
    return resetStore;
    // eslint-disable-next-line
  }, [contactId]);

  useEffect(() => {
    if(methods.formState.isDirty) {
      saveNote(contactNotes);
    }
  }, [contactNotes]);

  if(!contact) {
    return null;
  }

  return (
    <Surface.Content
      style={ SURFACE_CONTENT_WRAPPER_STYLE_PROPS }>
      <OverviewWrapper>
        <ContactInformation/>
        <BusinessDetails/>
        <NotesAndHistoryWrapper>
          <NotesAndHistory
            control={ methods.control }
            currentUser={ profile }
            isAddNoteBtnDisabled={ false }
            linkedContactData={ contact }
            names={ CONTACT_NOTES_AND_HISTORY_INPUT_NAMES }
            noteAndHistoryType={ NOTE_AND_HISTORY_TYPE.general }
            noteContactOptions={ notesUtilsStore.noteContactOptions }
            onAddEditEnd={ notesUtilsStore.toggleNotesPanelStateOff }
            onAddEditStart={ notesUtilsStore.toggleNotesPanelStateOn }
            predefinedTags={ predefinedTags }
            title={ 'Notes & History Timeline ' }
          />
        </NotesAndHistoryWrapper>
      </OverviewWrapper>
    </Surface.Content>
  );
});
